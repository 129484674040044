<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom" class=" bg-darkmode-600 ">
        <ion-tab-button tab="tab1" href="/tabs/tab1" class=" bg-darkmode-600">
          <ion-icon :icon="documentOutline"></ion-icon>
          <ion-label>Resumen</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="alltransaction" href="/all-transaction" class=" bg-darkmode-600 ">
          <ion-icon :icon="documentsOutline" />
          <ion-label>Histórico</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="tab2" href="/tabs/tab2" class=" bg-darkmode-600 ">
          <ion-icon :icon="walletOutline" />
          <ion-label>Recargar</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="PageFormalities" href="/formalities" class=" bg-darkmode-600 ">
          <ion-icon :icon="newspaperOutline" />
          <ion-label>Trámites</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { cashOutline, paperPlaneOutline, documentsOutline, walletOutline,newspaperOutline ,documentOutline } from 'ionicons/icons';

export default defineComponent({
  name: 'TabsPage',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
  setup() {
    return {
      cashOutline,
      paperPlaneOutline,
      documentsOutline,
      walletOutline,
      documentOutline,
      newspaperOutline
    }
  }
});
</script>
<style scoped>
ion-tab-bar {
  --border: solid 2px var(--ion-color-primary);
}
</style>